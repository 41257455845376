const context = {
  rootElementId: 'snippet--root', // application root html element
  vueApp: null, // root vue app instance
  ssr: false, // server side rendering
  prod: true, // app is running in production
  netteAjaxExt: [ // list of used nette ajax extensions
    'Init',
    'Abort',
    'Cache',
    'Unique',
    'Validation',
    'Confirm',
    'Prompt',
    'Spinner',
    'Form',
    'Redirect',
    'VueCallbacks',
    'VueRouter',
    'VueRender',
    'VueModal',
    'PageTitle'
    // 'VueNette',
    // 'VuePrefetch',
  ],
  liveForm: {
    // CSS class of control's parent where error/valid class should be added; or "false" to use control directly
    showMessageClassOnParent: false,

    // CSS class of control's parent where error/valid message should be added (fallback to direct parent if not found); or "false" to use control's direct parent
    messageParentClass: 'form-group',

    // CSS class for an invalid control
    controlErrorClass: 'is-invalid',

    // CSS class for a valid control
    controlValidClass: 'is-valid',

    // CSS class for an error message
    messageErrorClass: 'invalid-feedback',

    // control with this CSS class will show error/valid message even when control itself is hidden (useful for controls which are hidden and wrapped into special component)
    enableHiddenMessageClass: 'show-hidden-error',

    // control with this CSS class will have disabled live validation
    disableLiveValidationClass: 'no-live-validation',

    // control with this CSS class will not show valid message
    disableShowValidClass: 'no-show-valid',

    // tag that will hold the error/valid message
    messageTag: 'div',

    // class for tag that will hold the error/valid message
    messageClass: 'control-message',

    // message element id = control id + this postfix
    messageIdPostfix: '_message',

    // show this html before error message itself
    messageErrorPrefix: '',

    // show all errors when submitting form; or use "false" to show only first error
    showAllErrors: true,

    // show message when valid
    showValid: true,

    // delay in ms before validating on keyup/keydown; or use "false" to disable it
    wait: 500,

    // vertical screen offset in px to scroll after focusing element with error (useful when using fixed navbar menu which may otherwise obscure the element in focus); or use "false" for default behavior
    focusScreenOffsetY: false
  }
}

export default context