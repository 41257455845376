import { createRouter, createWebHistory } from 'vue-router'
import store from 'nette-base-module/js/store'

const BackLayout = () => import('@/backapp/views/Layout.vue')
const FrontLayout = () => {
  return window?.netteInitialState?.presenter?.editable
    ? import('@/frontapp/views/LayoutBack.vue')
    : import(/* webpackPreload: true */ '@/frontapp/views/Layout.vue')
}

const routes = [
  {
    path: '/admin/:pathMatch(.*)*',
    component: BackLayout
  },
  {
    path: '/:pathMatch(.*)*',
    component: FrontLayout
  }
]

// create vue router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash.includes('ambiFilter')) {
      // nic
    } else if (to.hash) {
      console.log(to.hash)
      // smooth scroll to element with id
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: 200 // header height
          })
        }, 100)
      })
    } else if (savedPosition) {
      return savedPosition // if navigate back, forward -> use last known scroll position
    } else if (to.path !== from.path) {
      // scroll to top
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (store.state.menu) {
    store.dispatch('menu/closeMenu')
  }

  document.documentElement.classList.add('page-enter-active')
  document.documentElement.classList.add('page-enter-from')

  next()
})

router.afterEach(() => {
  document.documentElement.classList.remove('page-enter-from')
})

export default router
